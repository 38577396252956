import { Box, BoxProps, Icon, Skeleton, Text } from '@chakra-ui/react';
import * as _ from 'lodash-es';
import { memo, useEffect, useRef } from 'react';
import { InlineWidget as CalendlyWidget } from 'react-calendly';
import { useForm, useWatch } from 'react-hook-form';

import { AlertIcon } from 'bundles/Classroom/icons';
import { BigButtonSelect } from 'components/BigButtonSelect';
import { FormatCurrency } from 'components/intl';
import { Markdown } from 'components/Markdown';
import { useAsync } from 'lib/hooks/useAsync';
import { loadScript } from 'lib/utils/loadScript';

import { PaymentPlan, useSignupFlow } from './signupFlowData';

import trustpilotImage from '../../../assets/images/logo-trustpilot.png';
import { SignupPaymentDrawer } from './SignupPaymentDrawer';
import { SelectPaymentPlanCountdownBanner } from './SelectPaymentPlanCountdownBanner';

export { trustpilotImage };

export const ScheduleACall: React.FC = () => {
  const {
    contact_data: contactData = {},
    show_schedule_a_call: showScheduleACall,
  } = useSignupFlow();

  if (!showScheduleACall) return null;

  return (
    <Box>
      <CalendlyWidget
        url="https://calendly.com/stepful/intro?hide_gdpr_banner=1"
        pageSettings={{
          backgroundColor: 'ffffff',
          hideEventTypeDetails: false,
          hideLandingPageDetails: false,
          primaryColor: 'c71a4b',
          textColor: '222525',
        }}
        prefill={{
          email: contactData.email,
          firstName: contactData.first_name,
          lastName: contactData.last_name,
          customAnswers: {
            a1: contactData.phone_number,
          },
        }}
      />
    </Box>
  );
};

const PaymentPlanOption: React.FC<{
  paymentPlan: PaymentPlan;
  cohort_and_payment_page_improvements_variant: boolean | undefined;
}> = ({ paymentPlan: pp, cohort_and_payment_page_improvements_variant }) => {
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="stretch"
        filter="grayscale(100%)"
        _groupChecked={{ filter: 'none' }}
      >
        <Text
          color={
            cohort_and_payment_page_improvements_variant
              ? 'gray.600'
              : 'brand.500'
          }
          fontSize="sm"
        >
          {pp.public_title}
        </Text>
        <Text>
          {pp.public_full_price_amount > pp.public_amount ? (
            <Text
              as="span"
              textDecoration="line-through"
              color="gray.500"
              mr="1.5"
              fontSize="2xl"
            >
              <FormatCurrency value={pp.public_full_price_amount} />
            </Text>
          ) : null}
          <Text as="span" fontSize="2xl" fontWeight="bold">
            <FormatCurrency value={pp.public_amount} />
          </Text>

          {pp.public_subtitle ? (
            <Text
              as="span"
              display="none"
              _groupChecked={{ display: 'inline-block ' }}
              fontSize="lg"
              fontWeight="bold"
              ml="1.5"
            >
              {pp.public_subtitle}
            </Text>
          ) : null}
        </Text>
        {!cohort_and_payment_page_improvements_variant && (
          <Box
            display="none"
            _groupChecked={{ display: 'flex' }}
            alignItems="center"
            color="brand.500"
            fontWeight="semibold"
          >
            <Icon as={AlertIcon} boxSize="5" mr="1.5" />
            Spots are Limited
          </Box>
        )}
        {pp.public_description ? (
          <Box
            display="none"
            _groupChecked={{ display: 'block' }}
            mt="4"
            color="gray.600"
            fontSize="sm"
          >
            <Markdown>{pp.public_description}</Markdown>
          </Box>
        ) : null}
      </Box>
    </>
  );
};

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    Trustpilot?: {
      loadFromElement: (el: HTMLElement) => void;
    };
  }
}

const dataToAttributes = (
  data: Record<string, string | number | null | undefined>,
  attrs = {} as Record<string, string>,
) => {
  Object.entries(data).forEach(([key, val]) => {
    if (val != null) attrs[`data-${_.kebabCase(key)}`] = val.toString();
  });
  return attrs;
};

type TrustpilotData = {
  templateId: string;
  theme?: 'light' | 'dark';
  locale?: string;
  styleWidth?: string;
  styleHeight?: string;
  tags?: string; // e.g. 'SelectedReview'
  starts?: string; // e.g. '3,4,5'
  reviewLanguages?: string; // e.g. 'en'
};

const TruspilotWidget_: React.FC<
  BoxProps & {
    data: TrustpilotData;
    children?: React.ReactNode;
  }
> = ({ data, children, ...props }) => {
  const { loading } = useAsync(
    () =>
      loadScript(
        'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js',
      ),
    [],
  );

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!loading && ref.current) {
      ref.current.classList.add('trustpilot-widget');
      window.Trustpilot?.loadFromElement(ref.current);
    }
  }, [loading]);

  const allData = {
    businessunitId: '6112ca6198c5f8001de7069f',
    theme: 'light',
    locale: 'en-US',
    ...data,
  } satisfies TrustpilotData & { businessunitId: string };

  return (
    <Box ref={ref} {...props} {...dataToAttributes(allData)}>
      {/* children will be overridden by Trustpilot sdk */}
      {children}
    </Box>
  );
};
const TruspilotWidget = memo(TruspilotWidget_, () => true); // never re-render

const TrustpilotRating: React.FC = () => {
  return (
    <TruspilotWidget
      data={{
        templateId: '5419b637fa0340045cd0c936',
        styleWidth: '230px',
        styleHeight: '20px',
      }}
      h="20px"
      mx="-2"
    >
      <Skeleton display="inline-block" w="60px" h="70%" mr="2" ml="2" />
      <Skeleton display="inline-block" w="70px" h="65%" mr="2" />
      <Skeleton display="inline-block" w="70px" h="75%" />
    </TruspilotWidget>
  );
};

const SocialProof: React.FC = () => {
  return (
    <Box pt={{ base: 6, md: 12 }} pb={{ base: 24, md: 10 }} px="2" bg="gray.50">
      <Text textAlign="center" fontSize="md" fontWeight="bold" mb="2">
        Our Graduates Love Stepful
      </Text>

      <TruspilotWidget
        data={{
          templateId: '54ad5defc6454f065c28af8b',
          styleWidth: '100%',
          styleHeight: '240px',
          tags: 'SelectedReview',
          reviewLanguages: 'en',
        }}
        h="240px"
      >
        <Box
          h="full"
          w="full"
          display="flex"
          justifyContent="center"
          overflowX="hidden"
          px="8"
          pt="6"
          gap={6}
        >
          {Array.from({ length: 5 }).map((_a, i) => (
            <Skeleton key={i} flexShrink={0} w="max(200px, 18%)" h="60%" />
          ))}
        </Box>
      </TruspilotWidget>
    </Box>
  );
};

export const SelectPaymentPlan: React.FC = () => {
  const {
    curriculum: { short_name, num_months, requires_externships } = {},
    payment_plans: paymentPlans,
    program_tagline,
    cohort_and_payment_page_improvements_variant,
  } = useSignupFlow();
  if (!paymentPlans?.length) throw new Error('No payment plans found');
  const form = useForm({
    defaultValues: {
      payment_plan_id: paymentPlans[0].id,
    },
  });

  const paymentPlanId = useWatch({
    control: form.control,
    name: 'payment_plan_id',
  });
  const selectedPlan = paymentPlans.find((pp) => pp.id === paymentPlanId);

  return (
    <Box>
      {cohort_and_payment_page_improvements_variant && (
        <SelectPaymentPlanCountdownBanner />
      )}
      <Box mx="auto" maxW="container.sm" px="4" pt="10" pb="12">
        <Box>
          <TrustpilotRating />

          <Text
            fontSize={{ base: 'xl', md: '2xl' }}
            lineHeight="short"
            fontWeight="600"
            mb="1"
            mt="2"
          >
            Online {short_name} Course
          </Text>

          <Text color="gray.600" fontWeight="400" fontSize="sm">
            {(!cohort_and_payment_page_improvements_variant &&
              program_tagline) || (
              <>
                {num_months} Month 100% Online Certification Program
                {requires_externships ? (
                  <>
                    <br />
                    In-Person Externship Included
                  </>
                ) : null}
              </>
            )}
          </Text>
        </Box>

        <Box py="8">
          <Text fontWeight="bold" fontSize="sm" color="gray.600" mb="4">
            Payment Plans
          </Text>

          <BigButtonSelect
            name="payment_plan"
            value={paymentPlanId}
            onChange={(val) => form.setValue('payment_plan_id', val!)}
            options={paymentPlans.map((pp) => ({
              key: pp.id,
              label: (
                <PaymentPlanOption
                  paymentPlan={pp}
                  cohort_and_payment_page_improvements_variant={
                    cohort_and_payment_page_improvements_variant
                  }
                />
              ),
            }))}
            targetProps={{
              position: 'relative',
            }}
          />
        </Box>

        <Box
          position={{ base: 'fixed', md: 'static' }}
          shadow={{ base: 'dark-lg', md: 'none' }}
          bottom={0}
          left={0}
          w="full"
          bg="white"
          p={{ base: '4', md: 0 }}
          zIndex={{ base: 1, md: 'auto' }}
        >
          <SignupPaymentDrawer paymentPlan={selectedPlan} />
        </Box>
        <ScheduleACall />
      </Box>

      <SocialProof />
    </Box>
  );
};
