import useSWR from 'swr';
import { useState } from 'react';

import { createProvider } from 'lib/providerHelpers';
import { request } from 'lib/request';
import { useRouteParams } from 'lib/routeBuilder';
import { Router } from 'components/Link';
import type { ClassTimingOption } from 'bundles/Classroom/Onboarding/data';

import { ROUTES } from './routes';

export type LeadAttributes = {
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
};

export type PaymentPlan = {
  id: RecordId;
  kind: 'upfront' | 'loan' | 'installments';
  public_title: string;
  public_subtitle: string | null;
  public_description: string | null;
  public_amount: number; // in cents
  public_cta: string | null;
  public_full_price_amount: number; // in cents
  one_off_amount: number; // in cents
};

// { <step>: [valuesFromBackend, valuesToSendToBackend] }
type Steps = {
  identifier_form: [
    { identifier_form: Partial<LeadAttributes> },
    { identifier_form: LeadAttributes },
  ];
  application_form: [
    {
      application_form: {
        typeform_form_id: string;
        hidden_fields: Record<string, string | null>;
      };
    },
    {
      application_form:
        | {
            typeform_form_id: string;
            typeform_response_id: string;
          }
        | {
            typeform_form_id: string;
            visited_fields_count: number;
          };
    },
  ];
  schedule_call: [
    { schedule_call: Partial<LeadAttributes> },
    { schedule_call: { call_scheduled: string } },
  ];
  select_payment_plan: [
    {
      payment_plans: PaymentPlan[];
      contact_data: Partial<LeadAttributes>;
      program_tagline: string;
      show_schedule_a_call: boolean;
      cohort_and_payment_page_improvements_variant: boolean;
    },
    {
      payment_plan_id: RecordId;
    },
  ];
  // TODO(spa-signup-flow): build embedded checkout flow:
  checkout: [{ checkout_url: string }, never];
  // checkout: [never, { stripe_setup_intent_id: string }];
  preview_timings: [
    {
      class_timing_options: ClassTimingOption[];
      cohort_and_payment_page_improvements_variant: boolean;
    },
    { selected_cohort_timing_id: RecordId },
  ];
  tsc_hubspot_form: [{ tsc_hubspot_form: { email: string } }, never];
  sign_in: [never, { password: string }];
  set_password: [never, { password: string }];
};

type SignupFlowStep = keyof Steps;

type SignupFlowResponse = {
  step: SignupFlowStep;
  progress_ratio: number;
  curriculum: {
    slug: string; // e.g. "ccma"
    name: string; // e.g. "CCMA"
    short_name: string;
    num_months: number;
    requires_externships: boolean;
    certification_name: string; // "Certified Clinical Medical Assistant" | "Certified Phlebotomy Technician"
  };
  features: Record<string, never>;
  payment_intent?: {
    client_secret: string;
    id: string;
  };
} & ExclusifyUnion<Steps[SignupFlowStep][0]>;

type UpdateData = Steps[SignupFlowStep][1] | void;

export const [SignupFlowProvider, useSignupFlow] = createProvider(() => {
  const { slug: curriculumSlug, action_code: actionCode } =
    useRouteParams(ROUTES.signup_flow) || {};
  if (!curriculumSlug) throw new Error('Missing curriculum slug');

  const {
    data: signupData,
    isLoading,
    mutate,
  } = useSWR<SignupFlowResponse>(
    `/curricula/${curriculumSlug}/signup/secure-your-spot${
      actionCode ? '/' + actionCode : ''
    }`,
    (url) => request({ url: url + '.json' }),
  );

  const [isUpdating, setIsUpdating] = useState(false);
  const update = async (updates: UpdateData) => {
    setIsUpdating(true);

    try {
      const newData = await mutate(
        request({
          url: `/curricula/${curriculumSlug}/signup/update`,
          method: 'PUT',
          body: {
            ...(updates || {}),
            current_step: signupData?.step, // just for tracking
            action_code: actionCode,
          },
        }),
        { revalidate: false },
      );

      const newCurriculum = newData?.curriculum.slug;

      if (newCurriculum && newCurriculum !== curriculumSlug) {
        Router.replace(
          ROUTES.signup_flow.path({
            slug: newCurriculum,
          }),
        );
      }

      // thrown errors are handled by the caller of `update`
    } finally {
      setIsUpdating(false);
    }
  };

  return {
    ...(signupData || ({} as Partial<SignupFlowResponse>)),
    curriculumSlug,
    isLoading,
    update,
    isUpdating,
  };
}, 'SignupFlow');
