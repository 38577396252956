import {
  Box,
  Button,
  Collapse,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Spacer,
  Input,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  useCallbackRef,
  useTimeout,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useMemo, useState } from 'react';
import { InlineWidget as CalendlyWidget } from 'react-calendly';
import {
  FormProvider,
  get,
  useForm,
  useFormContext,
  useFormState,
} from 'react-hook-form';
import { z } from 'zod';
import * as _ from 'lodash-es';
import { parseISO, isAfter, isBefore, setHours, setMinutes } from 'date-fns';
import { useHistory, useLocation } from 'react-router-dom';

import { RightCaretIcon } from 'bundles/Classroom/icons';
import { TypeformWidget } from 'bundles/Classroom/SurveyLesson';
import { Head } from 'components/Head';
import { LoadingShimmer } from 'components/LoadingShimmer';
import { MainNav } from 'components/navbar/MainNav';
import { request } from 'lib/request';
import { googleTagManagerTrack } from 'lib/trackingHelpers/eventTracking';
import { compactObj } from 'lib/utils';
import { ProgressBar } from 'components/ProgressBar';
import { useUpdateTimezone } from 'lib/hooks/useUpdateTimezone';
import { useLocalStorage } from 'lib/hooks/useLocalStorage';
import { ClassTimingOption } from 'bundles/Classroom/Onboarding/data';

import {
  LeadAttributes,
  SignupFlowProvider,
  useSignupFlow,
} from './signupFlowData';

import { PreviewClassTimings } from './PreviewClassTimings';
import { SelectPaymentPlan } from './SelectPaymentPlan';

type TypeformFieldType = { ref: string; formId: string };
declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    hbspt: {
      forms: {
        create: (formObject: any) => void;
      };
    };
  }
}

const ApplicationForm: React.FC = () => {
  const { application_form: { typeform_form_id, hidden_fields } = {}, update } =
    useSignupFlow();

  useUpdateTimezone();

  const [fieldsVisited, setFieldsVisited, clearFieldsVisited] = useLocalStorage<
    string[]
  >('visitedSignUpFields', []);

  const handleQuestionChange = (field: TypeformFieldType) => {
    const nextVisitedFields = [...new Set([...fieldsVisited, field.ref])];

    setFieldsVisited(nextVisitedFields);

    void update({
      application_form: {
        typeform_form_id: field.formId,
        visited_fields_count: nextVisitedFields.length,
      },
    });
  };

  if (!typeform_form_id)
    throw new Error('Missing acquisition form typeform id');

  return (
    <TypeformWidget
      id={typeform_form_id}
      style={{ height: 'calc(100vh - var(--chakra-sizes-16) - 8px - 6rem)' }} // iPhone high goes behind url bar + Stepful's Header
      inlineOnMobile
      hidden={compactObj(hidden_fields || {})}
      onQuestionChanged={useCallbackRef((field: TypeformFieldType) => {
        handleQuestionChange(field);
      })}
      onSubmit={useCallbackRef(({ responseId }) => {
        googleTagManagerTrack('Application Submitted');

        clearFieldsVisited();

        void update({
          application_form: {
            typeform_form_id,
            typeform_response_id: responseId,
          },
        });
      })}
    />
  );
};

const remoteValidate = async (values: {
  email?: string;
  phone_number?: string;
}) => {
  try {
    const { errors } = await request<{
      errors?: { email?: string; phone_number?: string };
    }>({
      // only adding `?keys` for tracking/debugging purposes
      url: '/form_validations/lead?keys=' + Object.keys(values).join(','),
      method: 'POST',
      body: values,
    });
    return errors || null;
  } catch {
    // if there's a network error, just let validation pass
    return null;
  }
};

const debouncePromise = <Fn extends (...args: any[]) => any>(
  fn: Fn,
  wait: number,
) => {
  let timeout: NodeJS.Timeout | null = null;
  return (...args: Parameters<Fn>) =>
    new Promise<ReturnType<Fn>>((resolve) => {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => resolve(fn(...args)), wait);
    });
};

// debounce validation to avoid spamming the server
const validateEmail = debouncePromise(async (email: string) => {
  const errors = await remoteValidate({ email });
  if (errors?.email) {
    return 'Please supply a valid email address';
  }
  return null;
}, 500);
const validatePhoneNumber = debouncePromise(async (phoneNumber: string) => {
  const errors = await remoteValidate({ phone_number: phoneNumber });
  if (errors?.phone_number) {
    return 'Please supply a valid phone number';
  }
  return null;
}, 500);

const identifierFormSchema = z.object({
  email: z
    .string()
    .min(1, 'Please supply your email address')
    .email('Please supply a valid email address')
    // use `.transform` b/c `.refine` ALWAYS runs, even if the previous validations fail
    .transform(async (email, ctx) => {
      const message = await validateEmail(email);
      if (message) ctx.addIssue({ code: 'custom', message, fatal: true });
      return email;
    }),
  first_name: z.string().min(1, 'Please supply your first name'),
  // TODO: should last-name be optional?
  last_name: z.string().min(1, 'Please supply your last name'),
  phone_number: z
    .string()
    // basic phone number regex
    .regex(/(\d.*){10,}/, 'Please supply a valid phone number')
    .transform(async (phoneNumber, ctx) => {
      const message = await validatePhoneNumber(phoneNumber);
      if (message) ctx.addIssue({ code: 'custom', message, fatal: true });
      return phoneNumber;
    }),
});
const identifierFormResolver = zodResolver(identifierFormSchema);

const FormField: React.FC<{
  name: keyof LeadAttributes;
  label: React.ReactNode;
  placeholder?: string;
  type?: string;
}> = ({ name, label, placeholder, type = 'text' }) => {
  const { register } = useFormContext();
  const { errors } = useFormState();
  const error = get(errors, name);

  return (
    <FormControl isInvalid={!!error}>
      <FormLabel>{label}</FormLabel>
      <Input type={type} placeholder={placeholder} {...register(name)} />
      <Collapse in={!!error} animateOpacity>
        <FormErrorMessage>{error?.message}</FormErrorMessage>
      </Collapse>
    </FormControl>
  );
};

const IdentifierForm: React.FC<{
  submitCta: React.ReactNode;
}> = ({ submitCta }) => {
  const { update, identifier_form: idf = {} } = useSignupFlow();

  const form = useForm({
    defaultValues: {
      email: idf.email || '',
      first_name: idf.first_name || '',
      last_name: idf.last_name || '',
      phone_number: idf.phone_number || '',
    } satisfies LeadAttributes,
    resolver: identifierFormResolver,
    mode: 'onTouched', // after the first 'blur' event, validatation is triggered on every change
  });

  useEffect(() => {
    // if form is empty on mount, don't show errors.
    // if form is not empty on mount, show errors and focus the first invalid input
    if (
      (['email', 'phone_number', 'first_name', 'last_name'] as const).some(
        (key) => idf[key],
      )
    )
      void form.trigger(undefined, { shouldFocus: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isValid = form.formState.isValid;
  const isSubmitting = form.formState.isSubmitting;

  return (
    <FormProvider {...form}>
      <Box
        as="form"
        noValidate
        onSubmit={form.handleSubmit(async (values) => {
          try {
            await update({ identifier_form: values });
          } catch (err) {
            form.setError('root', {
              type: 'server_validation',
              message: (err as Error).message,
            });
          }
        })}
      >
        <Stack spacing={4} direction="column" mb="8">
          <FormField name="email" label="Email" />
          <Stack spacing={4} direction={{ base: 'column', md: 'row' }}>
            <FormField name="first_name" label="First Name" />
            <FormField name="last_name" label="Last Name" />
          </Stack>
          <FormField name="phone_number" label="Phone Number" />
        </Stack>
        <Button
          type="submit"
          colorScheme="brand"
          w="full"
          isLoading={isSubmitting}
          isDisabled={isSubmitting || !isValid}
        >
          {submitCta}
        </Button>

        {form.formState.errors.root ? (
          <Text color="red.500" mt="4">
            {form.formState.errors.root.message}
          </Text>
        ) : null}
      </Box>
    </FormProvider>
  );
};

const IdentifierFormPage: React.FC = () => {
  const { curriculum } = useSignupFlow();

  return (
    <Box py="10" maxW="container.sm" mx="auto" px="4">
      <Box mb="16" textAlign="center">
        <Text
          color="gray.600"
          fontWeight="500"
          fontSize={{ base: '3xl', md: '4xl' }}
          lineHeight="short"
          mb="6"
        >
          Your new{' '}
          <Text as="span" fontWeight="bold" color="black">
            {curriculum?.certification_name}
          </Text>{' '}
          career starts here
        </Text>
        <Text fontSize={{ base: 'xl', md: '2xl' }}>
          Get a healthcare job or your tuition is on us
        </Text>
      </Box>
      <IdentifierForm submitCta="Apply now" />
      <Text
        color="brand.500"
        fontWeight="500"
        mt="10"
        textAlign="center"
        mx="10"
      >
        Spots are limited. We accept qualified applicants on a first come, first
        served basis.
      </Text>
    </Box>
  );
};

// https://help.calendly.com/hc/en-us/articles/223147027-Embed-options-overview?tab=advanced#hiding-the-cookie-banner-in-the-embed-0-7
function isCalendlyEvent(e: MessageEvent<any>): e is MessageEvent<{
  event: 'calendly.event_scheduled' | 'calendly.event_cancelled';
  payload: Record<string, unknown>;
}> {
  return (
    e.origin === 'https://calendly.com' &&
    e.data.event &&
    e.data.event.indexOf('calendly.') === 0
  );
}

// TODO: We will kill this step and handle with Action Codes
const ScheduleCall: React.FC = () => {
  const { schedule_call: prefillData = {} } = useSignupFlow();

  useEffect(() => {
    const cb = (e: MessageEvent<any>) => {
      if (isCalendlyEvent(e) && e.data.event === 'calendly.event_scheduled') {
        // TODO: show our own calendly confirmation page.
        // We need to decide what the user's next step is after scheduling a call.
        // console.log('Event name:', e.data.event);
        // console.log('Event details:', e.data.payload);
      }
    };
    window.addEventListener('message', cb);
    return () => window.removeEventListener('message', cb);
  }, []);

  return (
    <Box>
      <CalendlyWidget
        url="https://calendly.com/stepful/intro?hide_gdpr_banner=1"
        pageSettings={{
          backgroundColor: 'ffffff',
          hideEventTypeDetails: false,
          hideLandingPageDetails: false,
          primaryColor: 'c71a4b',
          textColor: '222525',
        }}
        prefill={{
          email: prefillData.email,
          firstName: prefillData.first_name,
          lastName: prefillData.last_name,
          customAnswers: {
            a1: prefillData.phone_number,
          },
        }}
      />
    </Box>
  );
};

const CheckoutStep: React.FC = () => {
  const { checkout_url } = useSignupFlow();
  if (!checkout_url) throw new Error('Missing checkout url');

  useTimeout(() => {
    window.location.href = checkout_url;
  }, 500);

  return <Box h="100vh" />;

  // TODO: move checkout flow to React
  //   <StripeCardForm
  //     onSetup={async (err, setupIntent) => {
  //       if (!setupIntent || err) return window.alert(err?.message || '???');

  //       await update({
  //         stripe_setup_intent_id: setupIntent.id,
  //       });
  //     }}
  //   />
  // );
};

const PreviewTimingsStep: React.FC = () => {
  const {
    class_timing_options,
    cohort_and_payment_page_improvements_variant,
    update,
    isUpdating,
    curriculum,
  } = useSignupFlow();
  const [selectedCohortTiming, setSelectedCohortTiming] =
    useState<RecordId | null>(null);

  const goNextStep = () => {
    if (
      cohort_and_payment_page_improvements_variant &&
      selectedCohortTiming !== null
    )
      void update({ selected_cohort_timing_id: selectedCohortTiming });
    else {
      void update();
    }
  };

  const [classTimingGrouping, setClassTimingGrouping] = useState<
    Record<string, ClassTimingOption[]>
  >({ popularClasses: [], additionalClasses: [] });

  useMemo(() => {
    if (class_timing_options) {
      // loop through class timings and sort by cohort start date and status
      const sorted_class_timing_options = _.orderBy(
        class_timing_options,
        [
          (option) => option.cohort.start_date,
          (option) =>
            option.cohort_timing.status === 100
              ? -100
              : option.cohort_timing.status || 0,
        ],
        ['asc', 'desc'],
      );

      let morningClassId = null;
      let eveningClassId = null;
      const popularClasses = [];
      const additionalClasses = [];

      // Add the first morning and evening classes to the popular classes, if available.
      // Make sure it's a reasonable morning and evening time in the student's timezone.
      for (const class_timing of sorted_class_timing_options) {
        if (class_timing.cohort_timing.status !== 100) {
          const date = parseISO(class_timing.cohort_timing.start_time);

          const after7_59am = isAfter(date, setMinutes(setHours(date, 7), 59));
          const before3_59pm = isBefore(
            date,
            setMinutes(setHours(date, 15), 59),
          );
          const after3_59pm = isAfter(date, setMinutes(setHours(date, 15), 59));

          if (after7_59am && before3_59pm && !morningClassId) {
            popularClasses.push(class_timing);
            morningClassId = class_timing.cohort_timing.id;
          } else if (after3_59pm && !eveningClassId) {
            popularClasses.push(class_timing);
            eveningClassId = class_timing.cohort_timing.id;
          }
        }
      }

      // Add the remaining classes to the popular classes until we have 3 popular classes.
      // The remainder will be added to the additional classes.
      for (const class_timing of sorted_class_timing_options) {
        if (
          class_timing.cohort_timing.id !== morningClassId &&
          class_timing.cohort_timing.id !== eveningClassId
        ) {
          if (
            popularClasses.length !== 3 &&
            class_timing.cohort_timing.status !== 100
          ) {
            popularClasses.push(class_timing);
          } else {
            additionalClasses.push(class_timing);
          }
        }
      }

      setClassTimingGrouping({
        popularClasses,
        additionalClasses,
      });
    }
  }, [class_timing_options]);

  // invalid data scenario:
  useEffect(() => {
    if (!class_timing_options?.length) goNextStep();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [class_timing_options]);
  if (!class_timing_options?.length) return null;

  return (
    <Box
      pt={{ base: 6, md: 16 }}
      {...(cohort_and_payment_page_improvements_variant
        ? { pb: { base: 24, md: 2 } }
        : {})}
      h={{ base: 'auto', md: 'calc(100vh - 4.5rem)' }}
      display="flex"
      flexDir="column"
      alignItems="stretch"
    >
      <Box
        maxW="container.sm"
        w="full"
        mx="auto"
        px="4"
        py={cohort_and_payment_page_improvements_variant ? '4' : '0'}
        mb={cohort_and_payment_page_improvements_variant ? '0' : '2'}
      >
        <Text fontSize={{ base: 'xl', md: '2xl' }} fontWeight="bold" mb="4">
          {curriculum?.short_name} Classes
        </Text>
        <UnorderedList color="gray.500" mb="6" fontSize="sm">
          <ListItem>Live 90-min Zoom Classes • 2 days/week</ListItem>
          <ListItem>Daily Lessons & Assignments • 4 days/week</ListItem>
        </UnorderedList>

        {cohort_and_payment_page_improvements_variant ? (
          <>
            <Text fontWeight="bold" fontSize="md" mb="2">
              Most Popular Classes
            </Text>
            <Text fontSize="sm" mb="3">
              Ranked based on time and availability
            </Text>
            <PreviewClassTimings
              classTimingOptions={classTimingGrouping.popularClasses}
              selectedCohortTiming={selectedCohortTiming}
              setSelectedCohortTiming={setSelectedCohortTiming}
              cohort_and_payment_page_improvements_variant
            />
            <div style={{ marginTop: 36 }} />
            {classTimingGrouping.additionalClasses?.length ? (
              <>
                <Text fontWeight="bold" fontSize="md" mb="3">
                  Additional classes
                </Text>
                <PreviewClassTimings
                  classTimingOptions={classTimingGrouping.additionalClasses}
                  selectedCohortTiming={selectedCohortTiming}
                  setSelectedCohortTiming={setSelectedCohortTiming}
                  cohort_and_payment_page_improvements_variant
                />
              </>
            ) : null}
          </>
        ) : (
          <PreviewClassTimings
            classTimingOptions={class_timing_options}
            selectedCohortTiming={selectedCohortTiming}
            setSelectedCohortTiming={setSelectedCohortTiming}
            cohort_and_payment_page_improvements_variant={false}
          />
        )}
      </Box>

      {!cohort_and_payment_page_improvements_variant && <Spacer />}

      <Box
        {...(cohort_and_payment_page_improvements_variant
          ? {
              w: 'full',
              bg: 'white',
              p: '4',
              zIndex: { base: 1, md: 'auto' },
              maxW: 'container.sm',
              mx: 'auto',
              position: { base: 'fixed', md: 'static' },
              shadow: { base: 'dark-lg', md: 'none' },
              bottom: '0',
              left: '0',
            }
          : { textAlign: 'right', p: '4', position: 'sticky', bottom: '0' })}
      >
        <Button
          colorScheme={
            cohort_and_payment_page_improvements_variant
              ? selectedCohortTiming
                ? 'brand'
                : 'gray'
              : 'brand'
          }
          {...(cohort_and_payment_page_improvements_variant
            ? { w: 'full' }
            : {})}
          onClick={goNextStep}
          isLoading={isUpdating}
          isDisabled={
            isUpdating ||
            (cohort_and_payment_page_improvements_variant &&
              selectedCohortTiming === null)
          }
          rounded={cohort_and_payment_page_improvements_variant ? 'md' : 'full'}
          shadow="lg"
          _hover={{
            transform: 'scale(1.02)',
          }}
          rightIcon={
            <Icon
              as={RightCaretIcon}
              boxSize="6"
              ml="-1"
              animation="slideRight 1s ease-in-out infinite alternate"
              // animate icon back and forth horizontally
              css={`
                @keyframes slideRight {
                  0% {
                    transform: translateX(0);
                  }
                  100% {
                    transform: translateX(4px);
                  }
                }
              `}
            />
          }
        >
          See Payment Plans
        </Button>
      </Box>
    </Box>
  );
};

const ProgressHeader: React.FC<{
  progressRatio: number | null;
  title?: React.ReactNode;
}> = ({ progressRatio, title }) => {
  return (
    <>
      <MainNav
        title={title}
        h="16"
        shadow="none"
        borderBottom="1px"
        borderColor="gray.100"
      />

      <Box top={0} position="sticky" zIndex={1}>
        <ProgressBar progressRatio={progressRatio} />
      </Box>
    </>
  );
};

const HubspotForm = () => {
  const history = useHistory();
  const location = useLocation();
  const { tsc_hubspot_form: prefillData } = useSignupFlow();

  useEffect(() => {
    // prefill email in the hidden email field from query params
    const params = new URLSearchParams(location.search);
    params.set('email', prefillData?.email || '');
    history.replace({ search: params.toString() });

    const script = document.createElement('script');
    script.src = '//js.hsforms.net/forms/embed/v2.js';
    script.async = true;
    script.onload = () => {
      if (window?.hbspt) {
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '20503045',
          formId: '70cd0992-82ec-4ab1-9e2e-33379e137b02',
          target: '#hubspot-form',
        });
      }
    };
    document.body.appendChild(script);
  }, [location.search, prefillData?.email, history]);

  return <div id="hubspot-form" />;
};

const TscWorkflow = () => {
  return (
    <Box px="2">
      <Box
        border="1px"
        rounded="md"
        borderColor="gray.300"
        mx="auto"
        p="10"
        maxW="2xl"
        mt={{ base: '4', md: '20' }}
        boxShadow={{ base: 'none', md: 'xl' }}
      >
        <HubspotForm />
      </Box>
    </Box>
  );
};

const SignupFlowInner: React.FC = () => {
  const { step, curriculum, progress_ratio: progressRatio } = useSignupFlow();

  let content: React.ReactNode;
  switch (step) {
    case null:
    case undefined:
      content = <LoadingShimmer />;
      break;
    case 'application_form':
      content = <ApplicationForm />;
      break;
    case 'schedule_call':
      content = <ScheduleCall />;
      break;
    case 'identifier_form':
      content = <IdentifierFormPage />;
      break;
    case 'select_payment_plan':
      content = <SelectPaymentPlan />;
      break;
    case 'checkout':
      content = <CheckoutStep />;
      break;
    case 'preview_timings':
      content = <PreviewTimingsStep />;
      break;
    case 'tsc_hubspot_form':
      content = <TscWorkflow />;
      break;
    default:
      throw new Error(`Unknown step ${step}`);
  }

  return (
    <>
      <Head>
        <title>Stepful - {curriculum?.name} application</title>
      </Head>

      <ProgressHeader progressRatio={progressRatio ?? null} />

      <main>{content}</main>
    </>
  );
};

export const SignupFlow: React.FC = () => {
  return (
    <SignupFlowProvider>
      <SignupFlowInner />
    </SignupFlowProvider>
  );
};
